export const ResourcesData: any =
    [{
        "question": "Have you given all your employees labour laws compliant employment contracts?",
        "moreinfo": "These contracts should be signed by all parties and all clauses executable. ",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do all employees have clear, documented, understandable and measurable job descriptions?",
        "moreinfo": "These contracts should be signed by all parties and all clauses executable.",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have automated payroll system?",
        "moreinfo": "This system should calculate taxes, track leave days and other relevant statutories.",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have updated personel files for all employees?",
        "moreinfo": "This should include next of kin details",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have employee appraisal system and process?",
        "moreinfo": "This should give a clear progressive performance report on each employee. Commissions and bonuses can be based on this performance report.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you understand how Kenya Labour Laws affect and influence your business operations?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Are you equiped to manage restructuring your business? Whether for growth purposes, downsizing (including redunduncies), taking on new products/services and employees (TUPE)",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have Mental Health policy in place?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your business registered to NHIF, NSSF, PAYE and NITA?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Are you upto date in filing returns and making payments of NHIF, NSSF, PAYE and NITA?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have documented employee recruitment and induction process?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have WIBA (Work Injury Benefits Act) insurance cover for your employees?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you understand how WIBA (Work Injury Benefits Act) can affect your business?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your business have an Arbitration process for resolving employee related issues and disputes?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have HR manual that contains policies, procedures and forms?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    ]
