import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ResponsivePie } from "@nivo/pie";
import { OperationsData } from "../datasource/OperationsData";
import { CustomToast } from "../ReusableComponents/CustomToast";
import { getColor, getRank, getRemark } from "./FinanceAnalysis";
import React from "react";
import CallToAction from "../ReusableComponents/CallToAction";

const DigitalAnalysis = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { reset } = useForm();
  const [showdata, setShowdata] = useState<any>([]);
  const [showFinanceTotal, setShowFinanceTotal] = useState(0);
  const [results, setResults] = useState<any>([]);
  const navigatePage = useCallback((stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }, [navigate, user, db]);
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (props.storedData?.triages[props.lastKnownTriage].operations) {
        let digital = props.storedData?.triages[props.lastKnownTriage].answers.operations;
        calculateResults(digital);
        let total = 0;
        let objects = 0;
        Object.keys(digital).map((key: string) => {
          if (digital[key] !== "N/A") {
            if (digital[key] instanceof Object) {
              Object.keys(digital[key]).map((key2: string) => {
                total += Number(digital[key][key2]);
                objects++;
                return ("");
              })
            }
            else {
              total += Number(digital[key]);
              objects++;
            }
          }
          return ("");
        });
        total = (total / (objects * 90)) * 100;
        setShowFinanceTotal(total);
        setShowdata([
          {
            "id": "Operations",
            "label": "Operations",
            "value": total.toFixed(0),
            "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
          },
          {
            "id": "Areas to Improve",
            "label": "Areas to Improve",
            "color": "white",
            "value": (100 - total).toFixed(0),
          }
        ]);
      }
      else {
        CustomToast("error", "Please fill in the Risk & Operations section first to see all results");
        navigatePage(`${props.lastKnownTriage}/operations/1`)
      }
    }
  }, [props, reset, navigatePage]);
  const CenteredMetric = ({ centerX, centerY }: any) => {

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '40px',
          fontWeight: 600,
        }}
      >
        {showFinanceTotal.toFixed(0)}%
      </text>
    )
  }
  const calculateResults = (data: any) => {
    let dataDefaults = [
      {
        name: "Business Planning",
        show: data[1] || data[19] ? true : false,
        color: getColor((Number(data[1]) + Number(data[19])) / 2),
        value: getRemark((Number(data[1]) + Number(data[19])) / 2),
        rank: getRank((Number(data[1]) + Number(data[19])) / 2),
      },
      {
        name: "Business Structure",
        show: data[2],
        color: getColor(Number(data[2])),
        value: getRemark(Number(data[2])),
        rank: getRank(Number(data[2])),
      },
      {
        name: "Business Systems",
        show: data[3],
        color: getColor(Number(data[3])),
        value: getRemark(Number(data[3])),
        rank: getRank(Number(data[3])),
      },
      {
        name: "Cyber Security",
        show: data[4],
        color: getColor(Number(data[4])),
        value: getRemark(Number(data[4])),
        rank: getRank(Number(data[4])),
      },
      {
        name: "Capacity Building",
        show: data[5],
        color: getColor(Number(data[5])),
        value: getRemark(Number(data[5])),
        rank: getRank(Number(data[5])),
      },
      {
        name: "Key Relationship Management",
        show: data[6],
        color: getColor(Number(data[6])),
        value: getRemark(Number(data[6])),
        rank: getRank(Number(data[6])),
      },
      {
        name: "Supply Chain Management",
        show: data[7] || data[8] || data[9] ? true : false,
        color: getColor(Number(data[7] + data[8] + data[9]) / 3),
        value: getRemark(Number(data[7] + data[8] + data[9] / 3)),
        rank: getRank(Number(data[7] + data[8] + data[9]) / 3),
      },
      {
        name: "Inventory Management",
        show: data[10],
        color: getColor(Number(data[10])),
        value: getRemark(Number(data[10])),
        rank: getRank(Number(data[10])),
      },
      {
        name: "Product Planning",
        show: data[11],
        color: getColor(Number(data[11])),
        value: getRemark(Number(data[11])),
        rank: getRank(Number(data[11])),
      },
      {
        name: "Quality Control",
        show: data[12],
        color: getColor(Number(data[12])),
        value: getRemark(Number(data[12])),
        rank: getRank(Number(data[12])),
      },
      {
        name: "Statutory & Regulatory Compliance",
        show: data[13],
        color: getColor(Number(data[13])),
        value: getRemark(Number(data[13])),
        rank: getRank(Number(data[13])),
      },
      {
        name: "Succession Planning",
        show: data[14],
        color: getColor(Number(data[14])),
        value: getRemark(Number(data[14])),
        rank: getRank(Number(data[14])),
      },
      {
        name: "Intellectual Property Protection",
        show: data[15],
        color: getColor(Number(data[15])),
        value: getRemark(Number(data[15])),
        rank: getRank(Number(data[15])),
      },
      {
        name: "Disaster/Crisis Management",
        show: data[16],
        color: getColor(Number(data[16])),
        value: getRemark(Number(data[16])),
        rank: getRank(Number(data[16])),
      },
      {
        name: "Order Tracking Process",
        show: data[17],
        color: getColor(Number(data[17])),
        value: getRemark(Number(data[17])),
        rank: getRank(Number(data[17])),
      },
      {
        name: "Operations SOPs",
        show: data[18],
        color: getColor(Number(data[18])),
        value: getRemark(Number(data[18])),
        rank: getRank(Number(data[18])),
      },
    ];
    setResults(dataDefaults);
  }
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (<div className={"offset-md-2 offset-xl-4 col-md-8 col-xl-4"}>
    <div className="card" id="card-operations" key={"finance-report"}>
      <div className="card-body text-center px-4 pt-5 pt-md-5">
        <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Risk & Operations Report</p>
        <b>{props?.storedData?.triages[props.lastKnownTriage]?.date ?
          `As at: ${props?.storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
          : ""}</b>
        <div style={{ height: 300 }}>
          {showdata ? <ResponsivePie
            borderColor={"#0000000"}
            colors={{ datum: 'data.color' }}
            data={showdata}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.85}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            layers={['arcs', CenteredMetric]}
          /> : <></>}
        </div>
        <div>
          <form className="p-3 p-xl-4" method="post">
            <div className="text-start">
              <table className="table text-start">
                <thead>
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col" className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {results.sort((a: any, b: any) => a.rank - b.rank)
                    .map((data: any, index: any) => {
                      if (data.show) {
                        return (
                          <React.Fragment key={index}>
                            {index !== 0 && (results[index - 1]?.value) !== results[index]?.value ?
                              <tr>
                                <th scope="row"></th>
                                <td></td>
                              </tr> : <></>}
                            <tr key={index}>
                              <th scope="row">{data.name}</th>
                              <td className={`text-center ${data.color}`}>{data.value}</td>
                            </tr>
                          </React.Fragment>
                        )
                      }
                      else {
                        return (<></>)
                      }
                    })}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <hr />
      </div >

    </div >
  </div>
  )
}

export default DigitalAnalysis;