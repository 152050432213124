export const OperationsData: any =
    [{
        "question": "Do you have a current business plan?",
        "moreinfo": "A business plan is an important tool when you are starting, running, expanding or exiting a business. You should update it regularly (at least every 12 months) and include your:",
        "list": [
            "Business profile including vision and mission statements",
            "Product or service market analysis",
            "Marketing plan",
            "Legal and risk management plan",
            "Operating plan",
            "Human resource plan",
            "Finance plan",
            "Action plan.",

        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Are you operating under the right business structure to achieve your business goals?",
        "moreinfo": "The structure of your business may impact your operating costs, tax, potential personal liability, and how much control you have over the business. Common business structures include:",
        "list": [
            "Sole trader—the simplest structure, giving you full control",
            "Limited Liability Company—more complex, limits your liability because it's a separate legal entity",
            "Partnership—made up of 2 or more people who share income or losses",
            "Trust—where a trustee is responsible for business operations.",
        ],
        "footerText": "You may need to change your business structure over the life of your business to match your business goals.",
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your business possess these key systems required to manage your day-to-day operations?",
        "moreinfo": "",
        "list": [
            "Accounting System",
            "Payroll Management System",
            "Customer Relationship Management",
            "e-commerce",
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your business have a cyber security policy in place?",
        "moreinfo": "Cyber security is the protection of your technology and data from:",
        "list": [
            "Accidental or intentional illegal access",
            "Fraud",
            "Corruption",
            "Theft",
            "Damage",

        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ],
    },

    {
        "question": "Do you have Up-skilling Training Policy to equip your team with additional business skills to effectively manage your business?",
        "moreinfo": "Your team should have the time, skills, knowledge and attitude to support your business and help it meet its objectives.",
        "list": [
        ],
        "footerText": "If you are a sole trader this may mean having external partners to support you.",
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },

    {
        "question": "Do you have positive relationships with your key partners, distributors and suppliers?",
        "moreinfo": "These may include:",
        "list": [
            "Wholesalers",
            "Small and medium enterprises organisations and associations",
            "Businesses you collaborate with",
            "Suppliers of resources to your business",
            "Investors",
            "Professional services (e.g. lawyer, accountant, banker)",
            "Asset managers (e.g. landlord).",
        ],
        "footerText": "Positive relationships with your partners, distributors and suppliers includes knowing how they have been impacted by events such as the coronavirus (COVID-19) pandemic. You should keep updated on whether their activities have been limited or changed, or if they are 'business as usual'.",
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a central database with all your supliers' key details?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a process to evaluate your suppliers' performance?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have alternative suppliers for your key business supplies and raw materials?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know exact stock/inventory levels at any given time?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented production plan in place?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented Quality Control (QC) process in place?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },

    {
        "question": "Does your business have the correct permits, licences and insurance to operate?",
        "moreinfo": "These will depend on the products and services you offer (e.g. transport, farming, manufacturing) and where you offer them (e.g. in a city or regional area).",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a succession and/or exit plan for your business?",
        "moreinfo": "You may wish to sell or close your business, and having a plan in place will help with a smooth transition. Having a succession and/or exit plan will support you through a timed transfer of control or closure of your business. Your plan should consider:",
        "list": [
            "Legal",
            "Financial",
            "Training and development",
            "Human resource",
            "Intellectual property",
            "Property and resources.",
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know how to protect your business, products, services and ideas both in Kenya and internationally?",
        "moreinfo": "This may include:",
        "list": [
            "Intellectual property (KIPI)",
            "Brand name registration",
            "Product or technology patents.",
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your business prepared for a crisis or disaster?",
        "moreinfo": "A crisis can be anything that negatively impacts your business-as-usual activities, such as:",
        "list": [
            "a natural disaster",
            "a cyber-attack or violent crime",
            "a pandemic",
            "the loss of a critical staff member",
            "the loss of a critical infrastructure such as power or internet.",

        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a Process for Tracking product orders or service delivery?",
        "moreinfo": "This process ensures timely delivery of products/services and in good order.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented Standard Operating Procedures (SOPs) of your main business activities?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a current business strategic plan?",
        "moreinfo": "Idealy should be 2-5 strategic plan",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    ]
