export const financeData: any =
    [{
        "question": "Do you understand specific factors that impact your sales?",
        "moreinfo": "Many factors can affect your sales, including:",
        "list": [
            "Type and number of customers (demand)",
            "Cost of supplies",
            "Marketing (independently and through third parties)",
            "Outside forces."
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you understand your business's cost structure? And its components.",
        "moreinfo": "This covers all costs to run your business including:",
        "list": [
            "Fixed—such as rent, licence and permit fees",
            "Variable—such as electricity, produce, wages.",
            "Marketing (independently and through third parties)",
            "Cost of sales"
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know the break-even point for your business?",
        "moreinfo": "The break-even point is the point where you have sold sufficient products or services to cover your costs during an accounting period.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have an operating budget for the next 12 months?",
        "moreinfo": "An operating budget includes your business's expenses, future costs, and estimated income. Have you considered the impact of COVID-19 pandemic in your operating budget?",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Have you considered the impact of COVID-19 pandemic in your operating budget?",
        "moreinfo": "The break-even point is the point where you have sold sufficient products or services to cover your costs during an accounting period.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have suitable financial controls in place to track business performance?",
        "moreinfo": "This means:",
        "list": [
        ],
        "subQuestion": [
            "Defined approval rights & limits to approve specific activities",
            "Tracking your sales and costs against your budget or forecasts",
            "Having effective debt control",
            "Monthly ledger reconciliation and approval",
            "Accounting system access rights and controls",
            "Cash-flow management",
            "Management of Products/Services cost drivers",
            "Product/Service pricing process"
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have access to adequate funds?",
        "moreinfo": "Understanding the basics of raising funds is a vital business skill. Whether you are looking to start a new business, grow your existing business or manage through leaner times, access to debt or equity financing will support your goals.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have an Accounting System?",
        "moreinfo": "Such as: QuickBooks, Tally, Sage, SAP ",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented Procurement Process?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a Forecasting process of cash inflows and outflows to ensure that there is neither shortage nor surplus of cash with the business?",
        "moreinfo": "Sufficient funds must be available for purchase of materials, payment of wages and meeting day-to-day expenses",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a competent Bookkeeper or Accountant?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your bookkeeper or accountant have job challenges which can be addressed by re-training her on operational and task management skills?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your Bookkeeping upto date?",
        "moreinfo": "This includes: Posting/Recording of all sales invoices, supplier bills and other source documents, Reconciling all ledgers etc ",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have internal auditor (employee or consultant) to manage all internal control processes? ",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Have you done Annual Tax audit and KRA returns for the last 3 financial years?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a current valid Tax Compliance Certificate (TCC)?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you receive Accurate, Timely, Understandable and Comprehensive management accounting reports every month?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you receive Accurate, Timely, Understandable and Comprehensive management accounting reports every month?",
        "moreinfo": "It is important to put money aside for your tax and statutory obligations. Such obligations include:",
        "list": [
            "PAYE",
            "Corporate Tax",
            "VAT",
            "NHIF",
            "NSSF"
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Have you considered changing your business model to reduce costs or increase demand?",
        "moreinfo": "Change your business model by:",
        "list": [
            "Adding new services or experiences",
            "Collaborating with other operators",
            "Exploring new suppliers",
            "Opening to new markets."
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have enough cash reserves or forward orders to survive the next three months?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know the optimal Debt and Equity mix or limits for your business?",
        "moreinfo": "This means the amount of financing from your creditors, bank & other investors COMPARED to financing from shareholders.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a clearly defined and documented debtor management process?",
        "moreinfo": "Managing your debtors' book is essential to ensure the cash flow of your business is not affected. The sooner you can identify and act on late paying customers, the better your chance of getting paid. ",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you clearly know the Gross and Net profit margins on every product or service you sell?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    ]