import { collection, getDoc, getDocs, getFirestore, query } from "firebase/firestore";
import { FunctionComponent, useEffect, useState } from "react";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse } from "reactstrap";
import OverallReport from "./OverallReport";
import FinanceAnalysis from "./FinanceAnalysis";
import MarketingAnalysis from "./MarketingAnalysis";
import DigitalAnalysis from "./DigitalAnalysis";
import OperationsAnalysis from "./OperationsAnalysis";
import ResourceAnalysis from "./ResourceAnalysis";
import KeyRatioAnalysisResults from "./KeyRatioAnalysisResults";
import 'bootstrap/dist/js/bootstrap.min.js';

interface DashboardProps {

}

const Dashboard: FunctionComponent<DashboardProps> = () => {
    const [showModal, setShowModal] = useState(false);
    const app: any = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const [userData, setUserData] = useState<any>([]);
    const [selectedData, setSelectedData] = useState<any>({});
    const [selectedSection, setSelectedSection] = useState<number>(1);
    const [selectedTriage, setSelectedTriage] = useState<number>(0);
    useEffect(() => {
        const q = query(collection(db, "users"));

        getDocs(q).then((querySnapshot) => {
            let buffer: any = [];
            querySnapshot.forEach((doc) => {
                let innerBuffer = { id: doc.id, ...doc.data() };
                buffer.push(innerBuffer);
            });
            setUserData(buffer);
        });


    }, []);

    return (<>
        <h1>Users</h1>
        <div className="table-responsive">
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {userData?.map((user: any, index: number) => {
                    let toggler = "toggler"+index
                    return (<><tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.id}</td>
                        <td>{user.businessName}</td>
                        <td> {user.triages ? 
                        <Button size="sm" color={"success"} outline
                        type="button" data-bs-toggle="collapse" data-bs-target={"#"+toggler} aria-expanded="false" aria-controls={toggler}
                        >View More</Button> : <></>}</td>
                    </tr>
                        <tr><td colSpan={6} className="collapse" id={toggler}>

                                {user.triages ?
                                    Object.keys(user.triages).length === 0 ? <i className="text-danger">No Report is complete<br /></i> :

                                        <div className="card card-custom">
                                            <div className="card-body">
                                                <><b>Reports Available:</b><br />
                                                    <hr />
                                                    {user.triages ?
                                                        Object.keys(user.triages).map((triage: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <span key={index}>{index + 1}.&nbsp;
                                                                        {user.triages?.[triage].date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}</span>
                                                                    <br />
                                                                    {(user.triages?.[triage].digital ||
                                                                        user.triages?.[triage].finance ||
                                                                        user.triages?.[triage].keyRatio ||
                                                                        user.triages?.[triage].marketing ||
                                                                        user.triages?.[triage].operations ||
                                                                        user.triages?.[triage].resources) ? <>

                                                                        {user.triages?.[triage].finance && <Button outline onClick={() => {
                                                                            setSelectedSection(1);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>Finance Report</Button>}&nbsp;
                                                                        {user.triages?.[triage].digital && <Button outline onClick={() => {
                                                                            setSelectedSection(3);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>Digital Report</Button>}&nbsp;
                                                                        {user.triages?.[triage].keyRatio && <Button outline onClick={() => {
                                                                            setSelectedSection(6);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>Key Ratio Report</Button>}&nbsp;
                                                                        {user.triages?.[triage].marketing && <Button outline onClick={() => {
                                                                            setSelectedSection(2);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>Marketing Report</Button>}&nbsp;
                                                                        {user.triages?.[triage].operations && <Button outline onClick={() => {
                                                                            setSelectedSection(4);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>Operations Report</Button>}&nbsp;
                                                                        {user.triages?.[triage].resources && <Button outline onClick={() => {
                                                                            setSelectedSection(5);
                                                                            setSelectedTriage(triage);
                                                                            setSelectedData(user);
                                                                            setShowModal(true)
                                                                        }}>HR Report</Button>}&nbsp;
                                                                        {(user.triages?.[triage].digital &&
                                                                            user.triages?.[triage].finance &&
                                                                            user.triages?.[triage].keyRatio &&
                                                                            user.triages?.[triage].marketing &&
                                                                            user.triages?.[triage].operations &&
                                                                            user.triages?.[triage].resources) && <Button outline onClick={() => {
                                                                                setSelectedSection(7);
                                                                                setSelectedTriage(triage);
                                                                                setSelectedData(user);
                                                                                setShowModal(true)
                                                                            }}>Overall Report</Button>}
                                                                        <hr />
                                                                        <br />
                                                                    </> : <> <i className="text-danger">No Report is complete<br /></i><hr /></>}
                                                                </>)
                                                        })
                                                        : <></>}
                                                </></div></div>
                                    : <></>}

                            
                        </td>
                        </tr>
                    </>)
                })}

            </tbody>
        </table>
        </div>
        <Modal
            isOpen={showModal}
            fullscreen={true}
        >
            <ModalHeader>
                Overall Report for {selectedData.name}
            </ModalHeader>
            <ModalBody className="text-center">
                <>
                    <h3>User Info</h3>
                    <b>Name:</b> {selectedData.name}<br />
                    <b>Phone Number:</b> {selectedData.phoneNumber}<br />
                    <b>Email:</b> {selectedData.id}<br />
                    <b>Business Name:</b> {selectedData.businessName}<br />
                    <hr />
                    {selectedSection === 1 &&
                        <FinanceAnalysis storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 2 &&
                        <MarketingAnalysis storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 3 &&
                        <DigitalAnalysis storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 4 &&
                        <OperationsAnalysis storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 5 &&
                        <ResourceAnalysis storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 6 &&
                        <KeyRatioAnalysisResults storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                    {selectedSection === 7 &&
                        <OverallReport storedData={selectedData} lastKnownTriage={selectedTriage} />
                    }
                </>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => { setShowModal(false) }}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    </>);
}

export default Dashboard;
