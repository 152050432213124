import {toast} from "react-toastify"

export const CustomToast = (type: 'success' | 'error' | 'info' | 'warning', message: string) => {
    return toast[type](message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}