import { getAuth, signOut } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { Button, Collapse } from "reactstrap";
import { CustomToast } from "../ReusableComponents/CustomToast";

interface TopBarProps {
    lastKnownTriage: number;
    storedData: any;
}

const TopBar: FunctionComponent<TopBarProps> = (props: any) => {
    const auth = getAuth();
    const { storedData } = props;
    let navigate = useNavigate();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const user: any = auth.currentUser;
    const { pathname } = useLocation();
    const [sectionsOpen, setSectionsOpen] = useState(false);
    const [reportsOpen, setReportsOpen] = useState(false);
    let triageID = props.lastKnownTriage;
    const navigatePage = (stage: string) => {
        if (user) {
            const userRef = doc(db, 'users', user?.email);
            setDoc(userRef,
                {
                    stage: stage
                }, { merge: true });
            navigate(`/${stage}`);
        }
    }
    return (
        <div className="text-center">
            <img
                className="img-fluid ms-4 ms-sm-4"
                src={Logo}
                alt="Business Doctors Logo"
                width="200px"
            />
            <hr />
            <h1 className="fw-bold" style={{ marginTop: 10 }}>
                Admin Dashboard
            </h1>
            {(triageID || Number(triageID) === 0) && !isNaN(Number(triageID)) ? user &&
                <>
                    {/* <Button
                        color="secondary"
                        onClick={() => {
                            if (reportsOpen) {
                                setReportsOpen((prev: boolean) => !prev)
                            }
                            setSectionsOpen((prev: boolean) => !prev)
                        }}
                        style={{
                            marginBottom: '1rem'
                        }}
                    ><svg className="bi bi-menu-button-wide-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v2A1.5 1.5 0 0 0 1.5 5h13A1.5 1.5 0 0 0 16 3.5v-2A1.5 1.5 0 0 0 14.5 0h-13zm1 2h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1zm9.927.427A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0l-.396-.396zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path>
                        </svg> &nbsp;Sections Menu
                    </Button>&nbsp;
                    {storedData.digital || storedData.keyRatio || storedData.finance || storedData.marketing || storedData.operations || storedData.resources ? <><Button
                        color="primary"
                        onClick={() => {
                            if (sectionsOpen) {
                                setSectionsOpen((prev: boolean) => !prev)
                            }
                            setReportsOpen((prev: boolean) => !prev)
                        }}
                        style={{
                            marginBottom: '1rem'
                        }}
                    ><svg className="bi bi-receipt-cutoff" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"></path>
                            <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"></path>
                        </svg>&nbsp;Reports
                    </Button>&nbsp;</> : <></>} */}
                    <Button
                        color="secondary"
                        onClick={() => {
                            signOut(auth).then(() => {
                                // Sign-out successful.
                                CustomToast("success", "Successfully Logged you out");
                                navigate(`/signin`);
                            }).catch((error) => {
                                // An error happened.
                                CustomToast("error", "Unable to sign you out, please try again later");
                            });
                        }}
                        style={{
                            marginBottom: '1rem'
                        }}
                    ><svg className="bi bi-box-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"></path>
                            <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"></path>
                        </svg>&nbsp;Log out
                    </Button>
                    <Collapse isOpen={sectionsOpen}>
                        <nav className="nav nav-pills nav-fill mb-3">
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/prestart") && "active"}`} onClick={() => navigatePage("prestart")}>Start</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/finance") && "active"}`} onClick={() => navigatePage(`${triageID}/finance/1`)}>Financial Management</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/key-ratio-analysis") && "active"}`} onClick={() => navigatePage(`${triageID}/key-ratio-analysis`)}>Financial Ratio Analysis</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/marketing") && "active"}`} onClick={() => navigatePage(`${triageID}/marketing/1`)}>Marketing & Strategy</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/digital") && "active"}`} onClick={() => navigatePage(`${triageID}/digital/1`)}>Digital Presence</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/operations") && "active"}`} onClick={() => navigatePage(`${triageID}/operations/1`)}>Risk & Operations</button>
                            <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/resources") && "active"}`} onClick={() => navigatePage(`${triageID}/resources/1`)}>Human Resource</button>
                        </nav></Collapse>
                    <Collapse isOpen={reportsOpen}>
                        <nav className="nav nav-pills nav-fill mb-3">
                            {storedData.finance && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/finance-analysis") && "active"}`} onClick={() => navigatePage(`${triageID}/finance-analysis`)}>Financial Management Report</button>}
                            {storedData.keyRatio && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/key-ratio-analysis") && "active"}`} onClick={() => navigatePage(`${triageID}/key-ratio-analysis-results`)}>Financial Ratio Analysis Report</button>}
                            {storedData.marketing && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/marketing") && "active"}`} onClick={() => navigatePage(`${triageID}/marketing-analysis`)}>Marketing & Strategy Report</button>}
                            {storedData.digital && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/digital") && "active"}`} onClick={() => navigatePage(`${triageID}/digital-analysis`)}>Digital Presence Report</button>}
                            {storedData.operations && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/operations") && "active"}`} onClick={() => navigatePage(`${triageID}/operations-analysis`)}>Risk & Operations Report</button>}
                            {storedData.resources && <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes("/resources") && "active"}`} onClick={() => navigatePage(`${triageID}/resources-analysis`)}>Human Resource Report</button>}
                            {storedData.digital && storedData.keyRatio && storedData.finance && storedData.marketing && storedData.operations && storedData.resources ? <button className={`flex-sm-fill text-sm-center nav-link ${pathname.includes(`overall-report`) && "active"}`} onClick={() => navigatePage(`${triageID}/overall-report`)}>Overall Report</button> : <></>}
                        </nav></Collapse> </> : <></>}
            <hr />
        </div>

    );
}

export default TopBar;