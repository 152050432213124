export const KeyRatioData: any =
    [{
        "question": "Total Cash Sale",
        "key": "totalCashSale",
    },
    {
        "question": "Total Credit Sale",
        "key": "totalCreditSale",
    },
    {
        "question": "Cost of Goods Sold (COGS)",
        "key": "costOfGoodsSold",
    },
    {
        "question": "Net Profit",
        "key": "netProfit",
    },
    {
        "question": "Current Assets",
        "key": "currentAssets",
    },
    {
        "question": "Fixed Assets",
        "key": "fixedAssets",
    },
    {
        "question": "Current Liabilities",
        "key": "currentLiabilities",
    },
    {
        "question": "Long Term Liabilities",
        "key": "fixedLiabilities",
    },
    {
        "question": "Accounts Receivables",
        "key": "accountsReceivables",
    },
    {
        "question": "Total Inventory",
        "key": "totalInventory",
    },
    ];