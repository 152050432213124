
import './App.css';
import TopBar from './components/topbar';
import Section from './components/Section';
import Footer from './components/Footer';
import { firebaseConfig } from './firebase/firebase';
import { initializeApp } from '@firebase/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { Cube } from 'react-preloaders2';

function App() {
  initializeApp(firebaseConfig);
  const [loading, setIsloading] = useState(true);
  const [lastKnownTriage, setLastKnownTriage] = useState(0);
  const [storedData, setStoredData] = useState<any>({});
  const [section, setSection] = useState("");
  return (

    <header className="pt-2">
      <div className="container">
        <div className="row pt-5">

          {storedData && <TopBar lastKnownTriage={lastKnownTriage} storedData={storedData} />}
          <Section setIsloading={setIsloading} setLastKnownTriage={setLastKnownTriage}
            setSection={setSection}
            setStoredData={setStoredData} />
          <Footer />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Cube customLoading={loading} />
        </div>
      </div>
    </header>
  );
}

export default App;
