export const DigitalData: any =
    [{
        "question": "Do you have a documented Digital Marketing budget?",
        "moreinfo": "",
        "list": [

        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    }, {
        "question": "Do you have an active and engaging digital presence in these platforms?",
        "moreinfo": "",
        "list": [

        ],
        "subQuestion": [
            "Websites",
            "Facebook",
            "Twitter",
            "Linked In",
            "Instagram",
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented digital and social media content strategy plan?",
        "moreinfo": "This should explain how you plan, publish and promote content in order to achieve specific marketing goals and obectives.",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a way to measure the performance of each social media channel used?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have an upto date website for your business?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have an up to date corporate page for Linked in? ",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a content plan for each media channel?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is the content you create and distribute linked to an overall persona based strategy?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your website user gadget responsive? ",
        "moreinfo": "Adusts and optimises its appearance for desktop, tablets and smartphones",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your website SEO optimized?",
        "moreinfo": "Do you have carefully researched keywords you use on specific pages of your site to ensure you appear in search results?",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a site that is regularly updated with fresh new content and doesn't require coding expertise everytime you want to change anything?",
        "moreinfo": "",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Have you mapped buyer's journey through your website? From interested first time visitor to engaged prospect and loyal customer?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Is your Blog section on your website active?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you conduct a periodic website and social media audit?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know the conversion rate of each digital channel used?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    ]
