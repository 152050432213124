import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { KeyRatioData } from "../datasource/KeyRatioData";
import { useForm } from "react-hook-form";
import FinanceAnime from "../assets/img/keyanalysis.gif";

const KeyRatioAnalysis = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  let { id } = useParams();
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { register, handleSubmit, reset, setValue } = useForm();

  const navigatePage = (stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      if (stage.includes('key-ratio-analysis-results')) {
        setDoc(userRef,
          {
            stage: stage,
            triages: {
              [props.lastKnownTriage]: {
                keyRatio: true
              }
            }
          }, { merge: true });
      }
      else {
        setDoc(userRef,
          {
            stage: stage
          }, { merge: true });
      }
      navigate(`/${stage}`);
    }
  }
  useEffect(() => {
    if (props.storedData?.triages) {
      if (props.lastKnownTriage) {
        reset(props.storedData?.triages[props.lastKnownTriage].answers);
      }
    }
  }, [props, reset])
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const onSubmit = (data: any) => {
    if (user && props.lastKnownTriage) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          triages: {
            [props.lastKnownTriage]: {
              answers: {
                KeyRatioAnalysis: data.KeyRatioAnalysis
              }
            }
          }
        }, { merge: true });
      navigatePage(`${props.lastKnownTriage}/key-ratio-analysis-results`)
    }
  }
  return (<div className="card" id="card-keyanalysis" key={"key-ratio-analysis"}>
    <div className="card-body text-center px-4 py-5 px-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Financial Ratio Analysis</p>
      <img src={FinanceAnime} width="200px" alt="Finance Animation" />
      <div className="mt-3">

        <p>Enter your financial data (numbers) which shall be used to analyze your financial position.
          A complete Ratio Analysis Report will be available upon completion. </p>
        <form className="p-3 p-xl-4" name="keyRatioAnalysis" method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-start">
            <div className="mb-3">
              <label className="form-label"><b>Date of Your Financial Data</b></label>
              <input type="date" className="form-control mb-3"
                {...register(`KeyRatioAnalysis.dateOfData}`, { required: true })} />

              {KeyRatioData.map((data: any, index: number) => {
                return (<div key={index} className="mb-3">
                  <label className="form-label"><b>{index + 1}. {KeyRatioData[`${index}`].question}</b></label>
                  <input type="text" min={1} className="form-control" key={`${data[`${index}`]}`} placeholder={`${KeyRatioData[`${index}`].question}`}
                    {...register(`KeyRatioAnalysis.${KeyRatioData[`${index}`].key}`, { required: true })}
                    onKeyUp={(e: any) => {
                      setValue(`KeyRatioAnalysis.${KeyRatioData[`${index}`].key}`, e.target.value.toString().replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    }}
                  />
                  <div id="emailHelp" className="form-text">Please enter the Figures as a Number</div>
                </div>)
              })}
            </div>
          </div>
          <div className="mb-3" />
          <div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => { navigatePage(`${props.lastKnownTriage}/finance-analysis`) }}>
                  <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                  </svg>&nbsp;Back </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="submit"
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default KeyRatioAnalysis;