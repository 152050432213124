export const MarketingData: any =
    [{
        "question": "Do you have a marketing strategy that informs you about your marketing plan that informs about tactics that inform about your daily activities?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your business use multiple channels to generate leads?",
        "moreinfo": "These channels can be social media, direct marketing, Search Engine Optimization, networking events etc",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know the velocity of your funnel?",
        "moreinfo": "i.e. the number of visitors, contacts, leads, prospects and customers you need each month to hit your revenue target?",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know your key customer groups?",
        "moreinfo": "Do you have absolute clarity around the profile of your ideal customer; the actual buyer personas you target? Can you identify your customers through their:",
        "list": [
            "Demographics (e.g. age, income, occupation)",
            "Location",
            "Attitudes or interests."
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Can you identify your customer needs?",
        "moreinfo": "Your customers have different needs, wants and motivations. For example, they may use your product or service differently, or want to connect with you in different ways. Do you consider all the needs of all your customers when you design your products and services?",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Are you meeting the needs of your customers?",
        "moreinfo": "Does your business:",
        "list": [
            "Communicate with customers, suppliers or partners",
            "Invite feedback, ratings or reviews from customers",
            "Record customer feedback, complaints and report incidents",
            "Make sure there is a consistent way of delivering products and services, and engaging with customers, suppliers and partners.",

        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented Customer Complaints Resolution process?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have an Advertisement Plan for each of your products/services?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a well defined sales proposition?",
        "moreinfo": "Have you clearly articulated your company's point of difference when compared to your competition?",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you know why your customers choose your products and services?",
        "moreinfo": "Why do customers go to your business and not to your competitors? What makes your business unique? What are the key selling points of your products or services?",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you sell your products or services to several targeted groups of customers? Or do you rely heavily on selling to a small number of customers or customer groups?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Can you measure marketing activities success when you advertise and promote your products and services through different channels?",
        "moreinfo": "",
        "list": [
        ],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Does your sales team follow a structured sales process developed and tested by your company? ",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Can you measure the return on investment of your marketing budget and activities?",
        "moreinfo": "Ideally, you'll be tracking the ROI of your overall budget as well as individual campaigns to ascertain what's working and what isn't.",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a well documented Pricing Strategy?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    {
        "question": "Do you have a documented new product or service development plan and process in place?",
        "moreinfo": "",
        "list": [],
        "answers": [
            {
                "value": "Yes",
                "colour": "text-success",
                "weight": 90
            },
            {
                "value": "No",
                "colour": "text-danger",
                "weight": 5
            },
            {
                "value": "Unsure",
                "colour": "text-warning",
                "weight": 15
            },
            {
                "colour": "",
                "value": "Not Applicable",
                "weight": 0
            }
        ]
    },
    ]