import { useForm } from "react-hook-form";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { CustomToast } from "../ReusableComponents/CustomToast";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const SignIn = (props: any) => {
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data: any) => {
    setSubmitted(true);
    const auth = getAuth();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        if (user.email) {
          const docRef = doc(db, "users", user.email);
          getDoc(docRef).then((doc) => {

            if (doc.exists() && doc.data()?.isAdmin) {
              CustomToast('success', `Success! ${user.email} has been logged in successfully!`);
              setSubmitted(false);
              navigate(`/dashboard`);
            }
            else {
              CustomToast('error', 'You do not have access to the Admin Portal');
              setSubmitted(false);
              signOut(auth).then(() => {
                CustomToast('error', 'You have been signed out');
              }).catch((error) => {
                CustomToast("error", "Unable to sign you out, please try again later");
              });
            }
          });
        }
        else {
          CustomToast('error', 'The Credentials you are using may not be correct. Please try again');
        }

      })
      .catch((error) => {
        setSubmitted(false);
        CustomToast('error', 'The Credentials you are using may not be correct. Please try again');
      });
  };
  return (
    <div className={"offset-md-2 offset-xl-3 col-md-8 col-xl-6"}>
      <div className="card card-custom-bg">
        <div className="card-body text-center px-4 py-5 px-md-5">
          <p className="fs-3 fw-bold text-primary card-text mb-2" id="title-color">Sign In</p>
          <div>
            <form className="p-3 p-xl-4" method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="text-start mb-3">
                <label className="form-label">Enter Your Email Address:</label>
                <input
                  className="form-control"
                  type="email"
                  id="email-1"
                  placeholder="Email"
                  autoComplete="email"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="text-start mb-3">
                <label className="form-label">Enter A New Password:</label>
                <input
                  className="form-control"
                  type="password"
                  id="email-2"
                  autoComplete="current-password"
                  {...register("password", { required: true })}
                  placeholder="Password"
                />
              </div>
              <div className="mb-3" />
              <div>
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted ? <TailSpin wrapperStyle={{ display: "block" }} ariaLabel="loading-indicator" color="white" height={25} /> : "Login and Continue"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div>
  );
}
export default SignIn;