import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import SmeIllustration from '../assets/img/sme-process.png';
import { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Instructions = (props: any) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;
  const proceed = (newApp: boolean) => {
    if (user?.email) {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const userRef = doc(db, 'users', user?.email);
      if (!props.storedData?.triages || newApp) {
        let triageNumber = 0;
        if (props.storedData?.triages) {
          triageNumber = Object.keys(props.storedData?.triages).length
        }
        setDoc(userRef,
          {
            stage: `${triageNumber}/finance/1`,
            triageNumber: triageNumber,
            lastKnownTriage: triageNumber,
            triages: {
              [`${triageNumber}`]: {
                date: new Date(),
                answers: {},
                finance: false,
                digital: false,
                keyRatio: false,
                marketing: false,
                operations: false,
                resources: false,
              }
            }
          }, { merge: true });
        navigate(`/${props.storedData.triageNumber || 0}//finance/1`);
      } else {
        setShowModal(true);
      }
    }
  }
  const proceedOngoing = (id: number) => {
    if (user?.email) {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: `${id}/finance/1`,
          lastKnownTriage: id,
        }, { merge: true });
      navigate(`/${id}/finance/1`);
    }
  }
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (<>
    <div className="card">
      <div className="card-body text-center px-4 py-5 px-md-5">
        <div className="text-start">
          <p>
            The SME Triage is an online Business Diagnostics App enabling
            business owners to test their business performance through our
            carefully designed Quick Business Vitals Check and Comprehensive
            Business Health Check. We further offer specialized consultations
            to facilitate business recovery and growth.
          </p>
          <p><strong>Quick Business Vitals Check-up</strong></p>
          <p>
            This diagnostic process analyses your business most vital processes
            and gives a complete Vitals Status Report showing your business Strong
            areas, Deficient Areas and pottential issues & risks.
          </p>
          <p><strong>Comprehensive Business Health Check</strong></p>
          <p>
            This is an intensive diagnostic and analytical process designed to
            thoroughly investigate areas of concern identified during the Business
            Vitals Check. This will lead to generation of a Recovery Road-map and
            targeted KPIs.
          </p>
          <h5>Our Process</h5>
          <div className="text-center mx-auto">
            <img
              className="img-fluid mb-3"
              src={SmeIllustration}
              alt="Our Process illustrated"
            />
          </div>
          <h5>Start Your Business Recovery Journey Now</h5>
          <p>
            <strong>
              Answer questions to assess your business health status in:
            </strong>
          </p>
          <ul>
            <li>Financial Management</li>
            <li>Financial Ratio Analysis</li>
            <li>Marketing & Strategy</li>
            <li>Digital Presence</li>
            <li>Risk & Operations</li>
            <li>Human Resources</li>
          </ul>
          <p>
            <strong>Time required:&nbsp;</strong>
            <br />
            Approximately 12 minutes per section.
            <br />
          </p>
          <p>
            Your progress will be saved so you can finish the
            assessment later. You can continue on any device using
            the same email you used to sign up.
            <br />
          </p>
          <p>
            <strong>Privacy</strong>
            <br />
          </p>
          <p>
            <a href="https://www.business.qld.gov.au/privacy" target={"_blank"} rel="noreferrer" >
              Your responses are confidential. We value your privacy.
            </a>
            <br />
          </p>
        </div>
        <div className="mb-3" />
        <div>
          <button
            className="btn btn-primary shadow d-block w-100"
            onClick={() => proceed(false)}
          >
            Take me to the Triage&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="bi bi-arrow-right"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <Modal
      isOpen={showModal}
    >
      <ModalHeader>
        Select Triage to Continue
      </ModalHeader>
      <ModalBody>
        <>
          Hello, We found you already have a Triage in progress.
          Please select a triage to continue or select create new one to create a new one.
          {props.storedData?.triages ? Object.keys(props.storedData?.triages).map((triage: any) =>
            <FormGroup check key={triage}>
              <Input
                name="triage"
                value={triage}
                onChange={(e) => { proceedOngoing(triage) }}
                type="radio"
              />
              {' '}
              <Label check>
                {props.storedData?.triages?.[triage].date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}
              </Label>
            </FormGroup>
          ) : <></>}
        </>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => { proceed(true) }}
        >
          Create New Triage
        </Button>
        {' '}
        <Button onClick={() => { setShowModal(false) }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </>
  );
}

export default Instructions;