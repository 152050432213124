import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart'
import CallToAction from '../ReusableComponents/CallToAction';
import { CustomToast } from '../ReusableComponents/CustomToast';
import { getColor, getRank, getRemark } from './FinanceAnalysis';
import { removeCommas } from './KeyRatioAnalysisResults';


const OverallReport = (props: any) => {
    const [showdata, setShowdata] = useState<any>([]);
    const [showdataDigital, setShowdataDigital] = useState<any>([]);
    const [showdataMarketing, setShowdataMarketing] = useState<any>([]);
    const [showdataOperations, setShowdataOperations] = useState<any>([]);
    const [showdataResource, setShowdataResource] = useState<any>([]);
    const [showFinanceTotal, setShowFinanceTotal] = useState(0);
    const [showResourceTotal, setShowResourceTotal] = useState(0);
    const [showOperationsTotal, setShowOperationsTotal] = useState(0);
    const [showDigitalTotal, setShowDigitalTotal] = useState(0);
    const [showMarketingTotal, setShowMarketingTotal] = useState(0);
    const [resultsFinance, setResultsFinance] = useState<any>([]);
    const [resultsOperations, setResultsOperations] = useState<any>([]);
    const [resultsMarketing, setResultsMarketing] = useState<any>([]);
    const [resultsDigital, setResultsDigital] = useState<any>([]);
    const [calculations, setCalculations] = useState<any>({});
    const [resultsHumanResource, setResultsHumanResource] = useState<any>([]);
    const { storedData, lastKnownTriage } = props;
    const calculateResults = (data: any) => {
        let dataDefaults = [
            {
                name: "Digital Planning",
                show: data[1],
                color: getColor(Number(data[1])),
                value: getRemark(Number(data[1])),
                rank: getRank(Number(data[1])),
            },
            {
                name: "Social Media Presence",
                show: data[2],
                color: getColor((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
                value: getRemark((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
                rank: getRank((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
            },
            {
                name: "Digital Marketing Plan",
                show: data[3],
                color: getColor(Number(data[3])),
                value: getRemark(Number(data[3])),
                rank: getRank(Number(data[3])),
            },
            {
                name: "Social Media KPIs",
                show: data[4],
                color: getColor(Number(data[4])),
                value: getRemark(Number(data[4])),
                rank: getRank(Number(data[4])),
            },
            {
                name: "Website Presence",
                show: data[5],
                color: getColor(Number(data[5])),
                value: getRemark(Number(data[5])),
                rank: getRank(Number(data[5])),
            },
            {
                name: "Corporate Page Visibility",
                show: data[6],
                color: getColor(Number(data[6])),
                value: getRemark(Number(data[6])),
                rank: getRank(Number(data[6])),
            },
            {
                name: "Digital Content Planning",
                show: data[7],
                color: getColor(Number(data[7])),
                value: getRemark(Number(data[7])),
                rank: getRank(Number(data[7])),
            },
            {
                name: "Content Creation Capability",
                show: data[8],
                color: getColor(Number(data[8])),
                value: getRemark(Number(data[8])),
                rank: getRank(Number(data[8])),
            },
            {
                name: "Website Responsiveness",
                show: data[9],
                color: getColor(Number(data[9])),
                value: getRemark(Number(data[9])),
                rank: getRank(Number(data[9])),
            },
            {
                name: "SEO Optimization",
                show: data[10],
                color: getColor(Number(data[10])),
                value: getRemark(Number(data[10])),
                rank: getRank(Number(data[10])),
            },
            {
                name: "Website Update Simplicity",
                show: data[11],
                color: getColor(Number(data[11])),
                value: getRemark(Number(data[11])),
                rank: getRank(Number(data[11])),
            },
            {
                name: "Buyer Journey Mapping",
                show: data[12] || data[13] ? true : false,
                color: getColor((Number(data[12] + data[13])) / 2),
                value: getRemark((Number(data[12] + data[13])) / 2),
                rank: getRank((Number(data[12] + data[13])) / 2),
            },
            {
                name: "Digital Audit",
                show: data[14],
                color: getColor(Number(data[14])),
                value: getRemark(Number(data[14])),
                rank: getRank(Number(data[14])),
            },
            {
                name: "Conversion Rate",
                show: data[15],
                color: getColor(Number(data[15])),
                value: getRemark(Number(data[15])),
                rank: getRank(Number(data[15])),
            },
        ];
        setResultsFinance(dataDefaults);
    }
    const calculateResultsDigital = (data: any) => {
        let dataDefaults = [
            {
                name: "Digital Planning",
                show: data[1],
                color: getColor(Number(data[1])),
                value: getRemark(Number(data[1])),
                rank: getRank(Number(data[1])),
            },
            {
                name: "Social Media Presence",
                show: data[2],
                color: getColor(Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])),
                value: getRemark(Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])),
                rank: getRank(Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])),
            },
            {
                name: "Digital Marketing Plan",
                show: data[3],
                color: getColor(Number(data[3])),
                value: getRemark(Number(data[3])),
                rank: getRank(Number(data[3])),
            },
            {
                name: "Social Media KPIs",
                show: data[4],
                color: getColor(Number(data[4])),
                value: getRemark(Number(data[4])),
                rank: getRank(Number(data[4])),
            },
            {
                name: "Website Presence",
                show: data[5],
                color: getColor(Number(data[5])),
                value: getRemark(Number(data[5])),
                rank: getRank(Number(data[5])),
            },
            {
                name: "Corporate Page Visibility",
                show: data[6],
                color: getColor(Number(data[6])),
                value: getRemark(Number(data[6])),
                rank: getRank(Number(data[6])),
            },
            {
                name: "Digital Content Planning",
                show: data[7],
                color: getColor(Number(data[7])),
                value: getRemark(Number(data[7])),
                rank: getRank(Number(data[7])),
            },
            {
                name: "Content Creation Capability",
                show: data[8],
                color: getColor(Number(data[8])),
                value: getRemark(Number(data[8])),
                rank: getRank(Number(data[8])),
            },
            {
                name: "Website Responsiveness",
                show: data[9],
                color: getColor(Number(data[9])),
                value: getRemark(Number(data[9])),
                rank: getRank(Number(data[9])),
            },
            {
                name: "SEO Optimization",
                show: data[10],
                color: getColor(Number(data[10])),
                value: getRemark(Number(data[10])),
                rank: getRank(Number(data[10])),
            },
            {
                name: "Website Update Simplicity",
                show: data[11],
                color: getColor(Number(data[11])),
                value: getRemark(Number(data[11])),
                rank: getRank(Number(data[11])),
            },
            {
                name: "Buyer Journey Mapping",
                show: data[12] || data[13] ? true : false,
                color: getColor(Number(data[12] + data[13])),
                value: getRemark(Number(data[12] + data[13])),
                rank: getRank(Number(data[12] + data[13])),
            },
            {
                name: "Digital Audit",
                show: data[14],
                color: getColor(Number(data[14])),
                value: getRemark(Number(data[14])),
                rank: getRank(Number(data[14])),
            },
            {
                name: "Conversion Rate",
                show: data[15],
                color: getColor(Number(data[15])),
                value: getRemark(Number(data[15])),
                rank: getRank(Number(data[15])),
            },
        ];
        setResultsDigital(dataDefaults);
    }
    const calculateResultsMarketing = (data: any) => {
        let dataDefaults = [
            {
                name: "Marketing Strategy",
                show: data[1],
                color: getColor(Number(data[1])),
                value: getRemark(Number(data[1])),
                rank: getRank(Number(data[1])),
            },
            {
                name: "Lead Generation",
                show: data[2],
                color: getColor(Number(data[2])),
                value: getRemark(Number(data[2])),
                rank: getRank(Number(data[2]))
            },
            {
                name: "Business Funnel Visibility",
                show: data[3],
                color: getColor(Number(data[3])),
                value: getRemark(Number(data[3])),
                rank: getRank(Number(data[3]))
            },
            {
                name: "Target Market Visibility",
                show: data[4] || data[5] || data[11] ? true : false,
                color: getColor((Number(data[4] + data[5] + data[11])) / 3),
                value: getRemark((Number(data[4] + data[5] + data[11])) / 3),
                rank: getRank((Number(data[4] + data[5] + data[11])) / 3),
            },
            {
                name: "Customer Satisfaction",
                show: data[6] || data[7] ? true : false,
                color: getColor((Number(data[7] + data[6])) / 2),
                value: getRemark((Number(data[7] + data[6])) / 2),
                rank: getRank((Number(data[7] + data[6])) / 2),
            },
            {
                name: "Product Advertisement",
                show: data[8],
                color: getColor(Number(data[8])),
                value: getRemark(Number(data[8])),
                rank: getRank(Number(data[8]))
            },
            {
                name: "Value Proposition",
                show: data[9] || data[10] ? true : false,
                color: getColor((Number(data[9] + data[10])) / 2),
                value: getRemark((Number(data[9] + data[10])) / 2),
                rank: getRank((Number(data[9] + data[10])) / 2),
            },
            {
                name: "Product/Service Promotion",
                show: data[12],
                color: getColor(Number(data[12])),
                value: getRemark(Number(data[12])),
                rank: getRank(Number(data[12]))
            },
            {
                name: "Sales Process",
                show: data[13],
                color: getColor(Number(data[13])),
                value: getRemark(Number(data[13])),
                rank: getRank(Number(data[13]))
            },
            {
                name: "Return on Investment(ROI)",
                show: data[14],
                color: getColor(Number(data[14])),
                value: getRemark(Number(data[14])),
                rank: getRank(Number(data[14]))
            },
            {
                name: "Pricing Strategy",
                show: data[15],
                color: getColor(Number(data[15])),
                value: getRemark(Number(data[15])),
                rank: getRank(Number(data[15]))
            },
            {
                name: "New Product Development",
                show: data[16],
                color: getColor(Number(data[16])),
                value: getRemark(Number(data[16])),
                rank: getRank(Number(data[16]))
            },
        ];
        setResultsMarketing(dataDefaults);
    }
    const calculateResultsOperations = (data: any) => {
        let dataDefaults = [
            {
                name: "Business Planning",
                show: data[1],
                color: getColor(Number(data[1])),
                value: getRemark(Number(data[1])),
                rank: getRank(Number(data[1])),
            },
            {
                name: "Business Structure",
                show: data[2],
                color: getColor(Number(data[2])),
                value: getRemark(Number(data[2])),
                rank: getRank(Number(data[2])),
            },
            {
                name: "Business Systems",
                show: data[3],
                color: getColor(Number(data[3])),
                value: getRemark(Number(data[3])),
                rank: getRank(Number(data[3])),
            },
            {
                name: "Cyber Security",
                show: data[4],
                color: getColor(Number(data[4])),
                value: getRemark(Number(data[4])),
                rank: getRank(Number(data[4])),
            },
            {
                name: "Capacity Building",
                show: data[5],
                color: getColor(Number(data[5])),
                value: getRemark(Number(data[5])),
                rank: getRank(Number(data[5])),
            },
            {
                name: "Key Relationship Management",
                show: data[6],
                color: getColor(Number(data[6])),
                value: getRemark(Number(data[6])),
                rank: getRank(Number(data[6])),
            },
            {
                name: "Supply Chain Management",
                show: data[7] || data[8] || data[9] ? true : false,
                color: getColor(Number(data[7] + data[8] + data[9])),
                value: getRemark(Number(data[7] + data[8] + data[9])),
                rank: getRank(Number(data[7] + data[8] + data[9])),
            },
            {
                name: "Inventory Management",
                show: data[10],
                color: getColor(Number(data[10])),
                value: getRemark(Number(data[10])),
                rank: getRank(Number(data[10])),
            },
            {
                name: "Product Planning",
                show: data[11],
                color: getColor(Number(data[11])),
                value: getRemark(Number(data[11])),
                rank: getRank(Number(data[11])),
            },
            {
                name: "Quality Control",
                show: data[12],
                color: getColor(Number(data[12])),
                value: getRemark(Number(data[12])),
                rank: getRank(Number(data[12])),
            },
            {
                name: "Statutory & Regulatory Compliance",
                show: data[13],
                color: getColor(Number(data[13])),
                value: getRemark(Number(data[13])),
                rank: getRank(Number(data[13])),
            },
            {
                name: "Succession Planning",
                show: data[14],
                color: getColor(Number(data[14])),
                value: getRemark(Number(data[14])),
                rank: getRank(Number(data[14])),
            },
            {
                name: "Intellectual Property Protection",
                show: data[15],
                color: getColor(Number(data[15])),
                value: getRemark(Number(data[15])),
                rank: getRank(Number(data[15])),
            },
            {
                name: "Disaster/Crisis Management",
                show: data[16],
                color: getColor(Number(data[16])),
                value: getRemark(Number(data[16])),
                rank: getRank(Number(data[16])),
            },
            {
                name: "Order Tracking Process",
                show: data[17],
                color: getColor(Number(data[17])),
                value: getRemark(Number(data[17])),
                rank: getRank(Number(data[17])),
            },
            {
                name: "Operations SOPs",
                show: data[18],
                color: getColor(Number(data[18])),
                value: getRemark(Number(data[18])),
                rank: getRank(Number(data[18])),
            },
        ];
        setResultsOperations(dataDefaults);
    }
    const calculateResultsResource = (data: any) => {
        let dataDefaults = [
            {
                name: "Employment Contracts",
                show: data[1],
                color: getColor(Number(data[1])),
                value: getRemark(Number(data[1])),
                rank: getRank(Number(data[1])),
            },
            {
                name: "Job Descriptions",
                show: data[2],
                color: getColor(Number(data[2])),
                value: getRemark(Number(data[2])),
                rank: getRank(Number(data[2])),
            },
            {
                name: "Payroll System",
                show: data[3],
                color: getColor(Number(data[3])),
                value: getRemark(Number(data[3])),
                rank: getRank(Number(data[3])),
            },
            {
                name: "Personel Database",
                show: data[4],
                color: getColor(Number(data[4])),
                value: getRemark(Number(data[4])),
                rank: getRank(Number(data[4])),
            },
            {
                name: "Appraisal System",
                show: data[5],
                color: getColor(Number(data[5])),
                value: getRemark(Number(data[5])),
                rank: getRank(Number(data[5])),
            },
            {
                name: "Labor Laws Knowledge",
                show: data[6],
                color: getColor(Number(data[6])),
                value: getRemark(Number(data[6])),
                rank: getRank(Number(data[6])),
            },
            {
                name: "Change Preparedness",
                show: data[7],
                color: getColor(Number(data[7])),
                value: getRemark(Number(data[7])),
                rank: getRank(Number(data[7])),
            },
            {
                name: "Mental Health Policy",
                show: data[8],
                color: getColor(Number(data[8])),
                value: getRemark(Number(data[8])),
                rank: getRank(Number(data[8])),
            },
            {
                name: "HR Compliance",
                show: data[9] || data[10] ? true : false,
                color: getColor((Number(data[9] + data[10])) / 2),
                value: getRemark((Number(data[9] + data[10])) / 2),
                rank: getRank((Number(data[9] + data[10])) / 2),
            },
            {
                name: "Recruitment Process",
                show: data[11],
                color: getColor(Number(data[11])),
                value: getRemark(Number(data[11])),
                rank: getRank(Number(data[11])),
            },
            {
                name: "WIBA Compliance",
                show: data[12] || data[13] ? true : false,
                color: getColor((Number(data[12] + data[13])) / 2),
                value: getRemark((Number(data[12] + data[13])) / 2),
                rank: getRank((Number(data[12] + data[13])) / 2),
            },
            {
                name: "Dispute Resolution",
                show: data[14],
                color: getColor(Number(data[14])),
                value: getRemark(Number(data[14])),
                rank: getRank(Number(data[14])),
            },
            {
                name: "HR Manual",
                show: data[15],
                color: getColor(Number(data[15])),
                value: getRemark(Number(data[15])),
                rank: getRank(Number(data[15])),
            },
        ];
        setResultsHumanResource(dataDefaults);
    }
    const CenteredMetricDigital = ({ centerX, centerY }: any) => {

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px',
                    fontWeight: 600,
                }}
            >
                {showDigitalTotal.toFixed(0)}%
            </text>
        )
    }
    const CenteredMetricFinance = ({ centerX, centerY }: any) => {

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px',
                    fontWeight: 600,
                }}
            >
                {showFinanceTotal.toFixed(0)}%
            </text>
        )
    }
    const CenteredMetricResource = ({ centerX, centerY }: any) => {

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px',
                    fontWeight: 600,
                }}
            >
                {showResourceTotal.toFixed(0)}%
            </text>
        )
    }
    const CenteredMetricOperations = ({ centerX, centerY }: any) => {

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px',
                    fontWeight: 600,
                }}
            >
                {showOperationsTotal.toFixed(0)}%
            </text>
        )
    }
    const CenteredMetricMarketing = ({ centerX, centerY }: any) => {

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px',
                    fontWeight: 600,
                }}
            >
                {showMarketingTotal.toFixed(0)}%
            </text>
        )
    }
    useEffect(() => {
        if (lastKnownTriage) {
            if (storedData?.triages[lastKnownTriage].finance) {
                let finance = storedData?.triages[lastKnownTriage].answers.finance;
                calculateResults(finance);
                let total = 0;
                let objects = 0;
                Object.keys(finance).map((key: string) => {
                    if (finance[key] !== "N/A") {
                        if (finance[key] instanceof Object) {
                            Object.keys(finance[key]).map((key2: string) => {
                                if (finance[key][key2] !== "N/A") {
                                    total += Number(finance[key][key2]);
                                    objects++;
                                }
                                return ("");
                            })
                        }
                        else {
                            total += Number(finance[key]);
                            objects++;
                        }
                    }
                    return ("");
                });
                total = (total / (objects * 90)) * 100;
                setShowFinanceTotal(total);
                setShowdata([
                    {
                        "id": "Finance",
                        "label": "Finance",
                        "value": total.toFixed(0),
                        "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
                    },
                    {
                        "id": "Areas to Improve",
                        "label": "Areas to Improve",
                        "color": "white",
                        "value": (100 - total).toFixed(0),
                    }
                ]);
            }
            if (Object.entries(storedData?.triages[lastKnownTriage].answers.digital).length === 15) {
                let digital = storedData?.triages[lastKnownTriage].answers.digital;
                calculateResultsDigital(digital);
                let total = 0;
                let objects = 0;
                Object.keys(digital).map((key: string) => {
                    if (digital[key] !== "N/A") {
                        if (digital[key] instanceof Object) {
                            Object.keys(digital[key]).map((key2: string) => {
                                if (digital[key][key2] !== "N/A") {
                                    total += Number(digital[key][key2]);
                                    objects++;
                                }
                                return ("");
                            })
                        }
                        else {
                            total += Number(digital[key]);
                            objects++;
                        }
                    }
                    return ("");
                });
                total = (total / (objects * 90)) * 100;
                setShowDigitalTotal(total);
                setShowdataDigital([
                    {
                        "id": "Digital",
                        "label": "Digital",
                        "value": total.toFixed(0),
                        "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
                    },
                    {
                        "id": "Areas to Improve",
                        "label": "Areas to Improve",
                        "color": "white",
                        "value": (100 - total).toFixed(0),
                    }
                ]);
            }
            if (storedData?.triages[lastKnownTriage].keyRatio) {
                let { totalCashSale, netProfit, currentAssets, costOfGoodsSold, totalInventory, currentLiabilities,
                    accountsReceivables, fixedAssets, fixedLiabilities, totalCreditSale } = storedData?.triages[lastKnownTriage].answers.KeyRatioAnalysis;
                let gmr = (removeCommas(totalCashSale) - removeCommas(costOfGoodsSold)) / removeCommas(totalCashSale);
                let npm = removeCommas(netProfit) / removeCommas(totalCashSale);
                let at = (removeCommas(currentAssets) - removeCommas(totalInventory)) / removeCommas(currentLiabilities);
                let wc = (removeCommas(currentAssets) - removeCommas(currentLiabilities));
                let roa = (removeCommas(netProfit) / removeCommas(currentAssets));
                let dr = (removeCommas(fixedLiabilities) + removeCommas(currentLiabilities)) / (removeCommas(fixedAssets) + removeCommas(currentAssets));
                let art = (removeCommas(totalCreditSale) / removeCommas(accountsReceivables));
                setCalculations({
                    gmr: gmr, npm: npm, at: at, wc: wc, roa: roa, dr: dr, art: art
                })
            }
            if (storedData?.triages[lastKnownTriage].marketing) {
                let marketing = storedData?.triages[lastKnownTriage].answers.marketing;
                calculateResultsMarketing(marketing);
                let total = 0;
                let objects = 0;
                Object.keys(marketing).map((key: string) => {
                    if (marketing[key] !== "N/A") {
                        total += Number(marketing[key]);
                        objects++;
                    }
                    return ("");
                });
                total = (total / (objects * 90)) * 100;
                setShowMarketingTotal(total);
                setShowdataMarketing([
                    {
                        "id": "Marketing",
                        "label": "Marketing",
                        "value": total.toFixed(0),
                        "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
                    },
                    {
                        "id": "Areas to Improve",
                        "label": "Areas to Improve",
                        "color": "white",
                        "value": (100 - total).toFixed(0),
                    }
                ]);
            }
            if (storedData?.triages[lastKnownTriage].operations) {
                let digital = storedData?.triages[lastKnownTriage].answers.operations;
                calculateResultsOperations(digital);
                let total = 0;
                let objects = 0;
                Object.keys(digital).map((key: string) => {
                    if (digital[key] !== "N/A") {
                        if (digital[key] instanceof Object) {
                            Object.keys(digital[key]).map((key2: string) => {
                                total += Number(digital[key][key2]);
                                objects++;
                                return ("");
                            })
                        }
                        else {
                            total += Number(digital[key]);
                            objects++;
                        }
                    }
                    return ("");
                });
                total = (total / (objects * 90)) * 100;
                setShowOperationsTotal(total);
                setShowdataOperations([
                    {
                        "id": "Operations",
                        "label": "Operations",
                        "value": total.toFixed(0),
                        "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
                    },
                    {
                        "id": "Areas to Improve",
                        "label": "Areas to Improve",
                        "color": "white",
                        "value": (100 - total).toFixed(0),
                    }
                ]);
            }
            if (storedData?.triages[lastKnownTriage].resources) {
                let digital = storedData?.triages[lastKnownTriage].answers.resources;
                calculateResultsResource(digital);
                let total = 0;
                let objects = 0;
                Object.keys(digital).map((key: string) => {
                    if (digital[key] !== "N/A") {
                        if (digital[key] instanceof Object) {
                            Object.keys(digital[key]).map((key2: string) => {
                                total += Number(digital[key][key2]);
                                objects++;
                                return ("");
                            })
                        }
                        else {
                            total += Number(digital[key]);
                            objects++;
                        }
                    }
                    return ("");
                });
                total = (total / (objects * 90)) * 100;
                setShowResourceTotal(total);
                setShowdataResource([
                    {
                        "id": "Human Resource",
                        "label": "Human Resource",
                        "value": total.toFixed(0),
                        "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
                    },
                    {
                        "id": "Areas to Improve",
                        "label": "Areas to Improve",
                        "color": "white",
                        "value": (100 - total).toFixed(0),
                    }
                ]);
            }


        }
        // window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [props])

    return (<>
        <div className="container text-center" >
            <h2><b>Overall Report</b></h2>
            <b>{storedData?.triages[props.lastKnownTriage]?.date ?
                `As at: ${storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
                : ""}</b>
            <div className='offset-sm-3 col-md-6' style={{ minHeight: 300 }}>
                <GaugeChart
                    colors={["#FF0000", "#FDFF00", "#00FF00"]}
                    id="gauge-chart1" animate={true}
                    textColor="#000000"
                    percent={((showFinanceTotal + showDigitalTotal + showMarketingTotal + showOperationsTotal + showResourceTotal) / 5) / 100}
                    nrOfLevels={6}
                    style={{ height: 100 }} />
            </div>
            <hr />
            <div className='row'>
                <div className='col col-lg-4'>
                    <strong>Finance Management Report</strong>
                    <div style={{ height: 250 }}>
                        {showdata ? <ResponsivePie
                            borderColor={"#0000000"}
                            colors={{ datum: 'data.color' }}
                            data={showdata}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            layers={['arcs', CenteredMetricFinance]}
                        /> : <></>}
                    </div>
                    <table className="table text-start mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultsFinance && resultsFinance.sort((a: any, b: any) => a.rank - b.rank)
                                .map((data: any, index: any) => {
                                    if (data.show) {
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (resultsFinance[index - 1]?.value) !== resultsFinance[index]?.value ?
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                    </tr> : <></>}
                                                <tr key={index}>
                                                    <th scope="row">{data.name}</th>
                                                    <td className={`text-center ${data.color}`}>{data.value}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div className='col col-lg-4'>
                    <b>Financial Ratio Analysis Report</b>
                    <table className="table text-start mt-5 mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Score</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Gross Margin Ratio</th>
                                <td className={`text-center ${calculations.gmr < 40 ? "table-danger-custom" : calculations.gmr < 70 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.gmr?.toFixed(2)}%</td>
                                <td>{calculations.gmr < 40 ? "Poor" : calculations.gmr < 70 ? "Fair" : "Excellent"} Operating Efficiency</td>
                            </tr>
                            <tr>
                                <th scope="row">Net Profit Margin</th>
                                <td className={`text-center ${calculations.npm < 15 ? "table-danger-custom" : calculations.npm < 30 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.npm?.toFixed(2)}%</td>
                                <td>{calculations.npm < 15 ? "Poor" : calculations.npm < 30 ? "Fair" : "Excellent"} Profitability Status</td>
                            </tr>
                            <tr>
                                <th scope="row">The Acid Test</th>
                                <td className={`text-center ${calculations.at < 0.5 ? "table-danger-custom" : calculations.at < 1 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.at?.toFixed(2)}%</td>
                                <td>{calculations.at < 0.5 ? "Poor" : calculations.at < 1 ? "Fair" : "Excellent"} Liquidity</td>
                            </tr>
                            <tr>
                                <th scope="row">Working Capital</th>
                                <td className={`text-center ${calculations.wc < 1 ? "table-danger-custom" : calculations.wc < 1.5 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.wc?.toFixed(2)}</td>
                                <td>{calculations.wc < 1 ? "Poor" : calculations.wc < 1.5 ? "Fair" : "Excellent"} Liquidity Level</td>
                            </tr>
                            <tr>
                                <th scope="row">Return on Assets(ROA)</th>
                                <td className={`text-center ${calculations.roa < 10 ? "table-danger-custom" : calculations.roa < 20 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.roa?.toFixed(2)}</td>
                                <td>{calculations.roa < 10 ? "Low" : calculations.roa < 20 ? "Average" : "High"} Efficient Use of Assets to generate Profit</td>
                            </tr>
                            <tr>
                                <th scope="row">Debt Ratio</th>
                                <td className={`text-center ${calculations.dr < 0.4 ? "table-danger-custom" : calculations.dr < 0.6 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.dr?.toFixed(2)}</td>
                                <td>{calculations.dr < 0.4 ? "Lowly" : calculations.dr < 0.6 ? "Average" : "Highly"} Leveraged & Risky</td>
                            </tr>
                            <tr>
                                <th scope="row">AR Turnover</th>
                                <td className={`text-center ${calculations.art < 6 ? "table-danger-custom" : calculations.art < 12 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.art?.toFixed(2)}</td>
                                <td>{calculations.art < 6 ? "Inefficient" : calculations.art < 12 ? "Average" : "Efficient"} Debt Collection Process</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div className='col col-lg-4'>
                    <strong>Marketing & Stategy Report</strong>
                    <div style={{ height: 250 }}>
                        {showdataMarketing ? <ResponsivePie
                            borderColor={"#0000000"}
                            colors={{ datum: 'data.color' }}
                            data={showdataMarketing}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            layers={['arcs', CenteredMetricMarketing]}
                        /> : <></>}
                    </div>
                    <table className="table text-start mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultsMarketing.sort((a: any, b: any) => a.rank - b.rank)
                                .map((data: any, index: any) => {
                                    if (data.show) {
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (resultsMarketing[index - 1]?.value) !== resultsMarketing[index]?.value ?
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                    </tr> : <></>}
                                                <tr key={index}>
                                                    <th scope="row">{data.name}</th>
                                                    <td className={`text-center ${data.color}`}>{data.value}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div className='col col-lg-4'>
                    <strong>Digital Presence Report</strong>
                    <div style={{ height: 250 }}>
                        {showdataDigital ? <ResponsivePie
                            borderColor={"#0000000"}
                            colors={{ datum: 'data.color' }}
                            data={showdataDigital}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            layers={['arcs', CenteredMetricDigital]}
                        /> : <></>}
                    </div>
                    <table className="table text-start mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultsDigital && resultsDigital.sort((a: any, b: any) => a.rank - b.rank)
                                .map((data: any, index: any) => {
                                    if (data.show) {
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (resultsDigital[index - 1]?.value) !== resultsDigital[index]?.value ?
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                    </tr> : <></>}
                                                <tr key={index}>
                                                    <th scope="row">{data.name}</th>
                                                    <td className={`text-center ${data.color}`}>{data.value}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div className='col col-lg-4'>
                    <b>Risk & Operations Report</b>
                    <div style={{ height: 250 }}>
                        {showdataOperations ? <ResponsivePie
                            borderColor={"#0000000"}
                            colors={{ datum: 'data.color' }}
                            data={showdataOperations}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            layers={['arcs', CenteredMetricOperations]}
                        /> : <></>}
                    </div>
                    <table className="table text-start mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultsOperations.sort((a: any, b: any) => a.rank - b.rank)
                                .map((data: any, index: any) => {
                                    if (data.show) {
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (resultsOperations[index - 1]?.value) !== resultsOperations[index]?.value ?
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                    </tr> : <></>}
                                                <tr key={index}>
                                                    <th scope="row">{data.name}</th>
                                                    <td className={`text-center ${data.color}`}>{data.value}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                        </tbody>
                    </table>
                    <hr />
                </div>
                <div className='col col-lg-4'>
                    <strong>Human Resource Report</strong>
                    <div style={{ height: 250 }}>
                        {showdataResource ? <ResponsivePie
                            borderColor={"#0000000"}
                            colors={{ datum: 'data.color' }}
                            data={showdataResource}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            layers={['arcs', CenteredMetricResource]}
                        /> : <></>}
                    </div>
                    <table className="table text-start mb-5">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultsHumanResource.sort((a: any, b: any) => a.rank - b.rank)
                                .map((data: any, index: any) => {
                                    if (data.show) {
                                        return (
                                            <React.Fragment key={index}>
                                                {index !== 0 && (resultsHumanResource[index - 1]?.value) !== resultsHumanResource[index]?.value ?
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                    </tr> : <></>}
                                                <tr key={index}>
                                                    <th scope="row">{data.name}</th>
                                                    <td className={`text-center ${data.color}`}>{data.value}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                        </tbody>
                    </table>
                    <hr />
                </div>

            </div>
        </div>
    </>);
}

export default OverallReport;