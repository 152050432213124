import { FunctionComponent, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import SignIn from "./SignIn";
import Finance from "./Finance";
import Instructions from "./Instructions";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Marketing from "./Marketing";
import Digital from "./Digital";
import Operations from "./Operations";
import Resources from "./Resources";
import Finished from "./Finished";
import KeyRatioAnalysis from "./KeyRatioAnalysis";
import FinanceAnalysis from "./FinanceAnalysis";
import MarketingAnalysis from "./MarketingAnalysis";
import DigitalAnalysis from "./DigitalAnalysis";
import OperationsAnalysis from "./OperationsAnalysis";
import ResourceAnalysis from "./ResourceAnalysis";
import KeyRatioAnalysisResults from "./KeyRatioAnalysisResults";
import OverallReport from "./OverallReport";
import Dashboard from "./Dashboard";
interface SectionProps {
  setIsloading: any;
  setLastKnownTriage: React.Dispatch<React.SetStateAction<number>>;
  setStoredData: React.Dispatch<any>;
  setSection: React.Dispatch<any>;
}

const Section: FunctionComponent<SectionProps> = (props: any) => {

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  let navigate = useNavigate();
  let [, setSection] = useState("");
  const [storedData, setStoredData] = useState<any>({});
  const userCase: any = auth.currentUser;
  const [lastKnownTriage, setLastKnownTriage] = useState<any>(0);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (!user.email) {
        props.setIsloading(false);
        navigate(`/signin`);
      }
      else {
        props.setIsloading(false);
      }
    }
    else {
      props.setIsloading(false);
    }
  }
  );
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/:triageID/finance/:id" element={<Finance storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/finance-analysis" element={<FinanceAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/marketing-analysis" element={<MarketingAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/digital-analysis" element={<DigitalAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/operations-analysis" element={<OperationsAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/resources-analysis" element={<ResourceAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/key-ratio-analysis-results" element={<KeyRatioAnalysisResults storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/key-ratio-analysis" element={<KeyRatioAnalysis storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/marketing/:id" element={<Marketing storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/digital/:id" element={<Digital storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/operations/:id" element={<Operations storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/resources/:id" element={<Resources storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/finished" element={<Finished lastKnownTriage={lastKnownTriage} />} />
      <Route path="/:triageID/overall-report" element={<OverallReport storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/prestart" element={<Instructions storedData={storedData} lastKnownTriage={lastKnownTriage} />} />
      <Route path="/undefined" element={<Navigate to="/" replace />} />
    </Routes>)
}

export default Section;