import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import Celebrate from "../assets/img/celebrate.webp";
import { ResourcesData } from "../datasource/ResourcesData";
import { useEffect } from "react";

const Finished = (props: any) => {
  let navigate = useNavigate();
  const auth = getAuth();
  const app = initializeApp(firebaseConfig);
  const user: any = auth.currentUser;
  const db = getFirestore(app);
  let { id } = useParams();
  const navigatePage = (stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }
  const proceed = () => {
    if (user?.email) {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: `${props.lastKnownTriage}/finance/1`,
          subSection: 1
        }, { merge: true });
      navigate(`${props.lastKnownTriage}/finance/1`);
    }
  }
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (<>
    <div className="card" id="card-finished">
      <div className="card-body text-center px-4 py-5 px-md-5">
        <img src={Celebrate} width="100px" />
        <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Well done!&nbsp;</p>
        <p>Congratulations on making it this far. Click <strong>See Report</strong> to see how your business is doing.</p>
        <div>
          <form className="p-3 p-xl-4" method="post">
            <div className="text-start"></div>
            <div className="mb-3"></div>
            <div className="row">
              <div className="col-6">
                <button className="btn btn-primary shadow d-block w-100" role="button" onClick={() => navigatePage(`${props.lastKnownTriage}/resources-analysis`)}><svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                </svg>&nbsp;Go Back</button>
              </div>
              <div className="col-6">
                <button className="btn btn-primary shadow d-block w-100" role="button" onClick={proceed}>See Report&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                </svg></button>
              </div>
            </div>
          </form>
        </div>
        <span className="card-link mt-5" onClick={() => navigatePage(`${props.lastKnownTriage}/overall-report`)}>
          <svg className="bi bi-arrow-counterclockwise" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path>
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>
          </svg>&nbsp;Start over
        </span>
      </div>
    </div>
  </>
  );
}

export default Finished;