import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DigitalData } from "../datasource/DigitalData";
import { useForm } from "react-hook-form";
import { CustomToast } from "../ReusableComponents/CustomToast";
import DigitalAnime from "../assets/img/digital.gif";

const Digital = (props: any) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  let { id } = useParams();
  const [question, setQuestion] = useState((id) ? parseInt(id) : 1)
  const user: any = auth.currentUser;
  const { register, getValues, reset } = useForm();

  const navigatePage = (stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      if (stage.includes('digital-analysis')) {
        setDoc(userRef,
          {
            stage: stage,
            triages: {
              [props.lastKnownTriage]: {
                digital: true
              }
            }
          }, { merge: true });
      }
      else {
        setDoc(userRef,
          {
            stage: stage
          }, { merge: true });
      }
      navigate(`/${stage}`);
    }
  }
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (props.storedData?.triages[props.lastKnownTriage].answers) {
        reset(props.storedData?.triages[props.lastKnownTriage].answers);
      }
      if (props.storedData?.stage?.split("/")[0] === "digital") {
        setQuestion(props.storedData?.stage?.split("/")[1])
      }
    }
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [props, reset])
  return (<div className="card" id="card-digital" key={question}>
    <div className="card-body text-center px-4 py-5 px-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Digital Presence</p>
      <img src={DigitalAnime} width="150px" alt="Finance Animation" />
      <div>
        <form className="p-3 p-xl-4" method="post">
          <div className="text-start">
            <p>
              <strong>
                {question}. {DigitalData[`${question - 1}`].question}
              </strong>
              <br />
            </p>
            <p>{DigitalData[`${question - 1}`].moreinfo}</p>
            <ul>
              {DigitalData[`${question - 1}`].list.map((value: string, key: number) =>
                <li key={key}>{value}</li>)}
            </ul>
            <div>
              {DigitalData[`${question - 1}`].subQuestion ?
                DigitalData[`${question - 1}`].subQuestion.map((value: any, key: number) =>
                  <div className="mb-4" key={key}>
                    <p><i>{value}</i></p>
                    {DigitalData[`${question - 1}`].answers.map((value: any, index: number) =>
                      <div className={`form-check ${value.colour}`} key={`digital${question}-${index}`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          {...register(`digital.${question}.${key}`, { required: true })}
                          value={value.weight === 0 ? "N/A" : value.weight}
                        />
                        <label className="form-check-label" htmlFor="formCheck-1">
                          {value.value}
                        </label>
                      </div>)}
                  </div>)
                : DigitalData[`${question - 1}`].answers.map((value: any, key: number) =>
                  <div className={`form-check ${value.colour}`} key={`digital${question}-${key}`}>
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register(`digital.${question}`, { required: true })}
                      value={value.weight === 0 ? "N/A" : value.weight}
                    />
                    <label className="form-check-label" htmlFor="formCheck-1">
                      {value.value}
                    </label>
                  </div>)
              }
            </div>
          </div>
          <div className="mb-3" />
          <div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    if ((question - 1) === 0) {
                      navigatePage(`${props.lastKnownTriage}/marketing-analysis`)
                    }
                    else if (pathname.includes("/digital") && question > 1) {
                      setQuestion((question) => Number(question) - 1);
                      navigatePage(`${props.lastKnownTriage}/digital/${question - 1}`)
                    }
                  }
                  }>
                  <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                  </svg>&nbsp;Back </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    if (getValues(`digital.${question}`)) {
                      if (user && props.lastKnownTriage) {
                        const userRef = doc(db, 'users', user?.email);
                        setDoc(userRef,
                          {
                            triages: {
                              [props.lastKnownTriage]: {
                                answers: {
                                  digital: { [`${question}`]: getValues(`digital.${question}`) }
                                }
                              }
                            }
                          }, { merge: true });
                      }
                      if (question >= DigitalData.length) {
                        navigatePage(`${props.lastKnownTriage}/digital-analysis`)
                      }
                      else if (pathname.includes("/digital")) {
                        setQuestion((question) => Number(question) + 1); navigatePage(`${props.lastKnownTriage}/digital/${Number(question) + 1}`)
                      }
                    }
                    else {
                      CustomToast("warning", "Please Fill In before Proceeding")
                    }
                  }}
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
      <span className="card-link mt-5" style={{ cursor: "pointer" }} onClick={() => navigatePage(`${props.lastKnownTriage}/digital/1`)}>
        <svg className="bi bi-arrow-counterclockwise" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path>
          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>
        </svg>&nbsp;Start over Section
      </span>
    </div>
  </div>
  )
}

export default Digital;